<template>
  <!-- eslint-disable-next-line -->
  <component :is="tag" class="algolia-highlight" v-html="html" />
</template>

<script>
export default {
  name: "AlgoliaHighlight",
  props: {
    field: {
      type: String,
      required: true
    },
    hit: {
      type: Object,
      required: true
    },
    tag: {
      type: String,
      default: "span"
    }
  },
  computed: {
    html() {
      let value = this.$_.get(this.hit, `_highlightResult.${this.field}`);

      if (this.$_.isArray(value)) {
        value = this.$_.head(value);
      }
      return this.$_.get(value, `value`, this.hit[this.field]);
    }
  }
};
</script>
